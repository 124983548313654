import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiHeader, BaseURL } from '../config/apiUrl';
import { Post } from '../Axios/AxiosFunctions';
import { saveLoginUserData, signOutRequest } from '../store/auth/authSlice';

const TokenContext = createContext();

export function TokenProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (token) {
                try {
                    const url = BaseURL('token/verify');
                    const headers = apiHeader();
                    const response = await Post(url, { token }, headers);
                    if (response !== undefined) {
                        await dispatch(saveLoginUserData(response));
                    } else {
                        await dispatch(signOutRequest());
                        navigate('/login');
                    }
                } catch (error) {
                    console.error('Token validation error:', error);
                    await dispatch(signOutRequest());
                    navigate('/login');
                }
            }
            setIsValidated(true);
        };

        validateToken();
    }, [location, dispatch, navigate]);

    if (!isValidated) {
        return null;
    }

    return <TokenContext.Provider value={{}}>{children}</TokenContext.Provider>;
}

export const useToken = () => useContext(TokenContext);