import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { RiFolderOpenFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Get, Post } from '../../Axios/AxiosFunctions';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import Loader from '../../Component/Loader';
import FolderAndFileBox from '../../Component/FolderAndFileBox';
import RecursiveTreeView from '../../Component/TreeView';
import { Button } from '../../Component/Button/Button';
import SearchInput from '../../Component/SearchInput';
import CustomBreadcrumb from '../../Component/CustomBreadcrumb';
import UploadFileInFolderModal from '../../modals/UploadFileInFolderModal';
import CreateFolderModal from '../../modals/CreateFolderModal';
import AreYouSureModal from '../../modals/AreYouSureModal';
import classes from './ViewTeamFolderDetail.module.css';

const NoFolderComp = () => {
  return (
    <div className={classes.noFolderComp}>
      <RiFolderOpenFill />
      <p>No Folders And Files Found In This Folder</p>
    </div>
  );
};

const CompanyFolderDetail = () => {
  const id = useParams()?.id;
  const token = useSelector((state) => state?.authReducer?.access_token);
  const userRole = useSelector((state) => state?.authReducer?.user?.role);
  const user = useSelector((state) => state?.authReducer?.user);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedFolder, subSelectedFolder] = useState(null);

  // treview cache
  const [treeItems, setTreeItems] = useState([]);
  // calculate previous folder
  const [prevFolder, setPrevFolder] = useState([]);
  // For  File and Folder Modal state
  const [show, setShow] = useState('');
  const [isUploading, setIsUploading] = useState('');
  const [action, setAction] = useState('');
  const [search, setSearch] = useState('');
  const [listingFolders, setListingFolders] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsToCopy, setItemsToCopy] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const isMyFolder = data[0]?.owner == user?._id;

  // create folder
  async function createFolder(e) {
    const url = BaseURL('data-room');
    let params = {
      ...e,
      parent: selectedFolder == null ? id : selectedFolder?._id,
    };
    setIsUploading('folder');

    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      const newData = JSON.parse(JSON.stringify(data));
      newData[0].children = [
        ...newData[0]?.children,
        response?.data?.data?.data[0],
      ];
      setData([...newData]);
      toast.success('Folder created successfully');
      setIsUploading('');
      setShow('');
    } else {
      setIsUploading('');
    }
  }

  // create file
  async function uploadFile(e) {
    const url = BaseURL('data-room');
    let params = {
      file: e?.file?.map((ite) => ite),
      parent: selectedFolder == null ? id : selectedFolder?._id,
    };
    const formData = new FormData();
    e?.roles?.map((item) => formData.append('roles[]', item));
    params?.file?.map((item) => formData.append('file', item));
    formData.append('parent', params?.parent);
    console.log(e?.file[0]?.key);
    setIsUploading('file');
    const response = await Post(url, formData, apiHeader(token));
    if (response !== undefined) {
      const newAllFoler = [...data];
      newAllFoler[0].children = [...newAllFoler[0].children];
      response?.data?.data?.data?.map((ite) => {
        newAllFoler[0].children.push(ite);
      });
      setData(newAllFoler);
      toast.success('File upload successfully');
      setIsUploading('');
      setShow('');
    } else {
      setIsUploading('');
    }
  }

  // get all folders
  const getAllTeamFolder = async () => {
    const url = BaseURL(
      `data-room?child=${selectedFolder == null ? id : selectedFolder?._id}`,
    );
    setLoading(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      setData(response?.data?.data?.folders);
      if (selectedFolder == null) {
        setPrevFolder([...prevFolder, response?.data?.data?.folders[0]]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setSearch('');
    setSelectedItems([]);
    setAllChecked(false);
    getAllTeamFolder();
  }, [selectedFolder]);

  const handleItemSelect = (dataId) => {
    if (!selectedItems.includes(dataId)) {
      setSelectedItems((prev) => [...prev, dataId]);
    } else {
      setSelectedItems((prev) => prev.filter((item) => item !== dataId));
    }
  };

  const handleAllChecked = () => {
    if (allChecked) {
      setSelectedItems([]);
    } else {
      listingFolders?.map((item, i) => {
        setSelectedItems((prev) => [...prev, item._id]);
      });
    }
    setAllChecked(!allChecked);
  };

  const handleCopyClicked = () => {
    setAction('copy');
    setItemsToCopy([...selectedItems]);
    toast.success('Selected items to copy', { autoClose: 1000 });
  };

  const handleCutClicked = () => {
    setAction('cut');
    setItemsToCopy([...selectedItems]);
    toast.success('Selected items to cut', { autoClose: 1000 });
  };

  async function handlePaste() {
    const url = BaseURL(action == 'copy' ? 'data-room/copy' : 'data-room/cut');
    const parent = selectedFolder?._id;
    const params = { parent, items: itemsToCopy };
    setLoading(true);
    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      setData(response?.data?.data?.folders);
      if (selectedFolder == null) {
        setPrevFolder([...prevFolder, response?.data?.data?.folders[0]]);
      }
      toast.success('Success', { autoClose: 1500 });
    }
    setLoading(false);
    setAction('');
    setSelectedItems([]);
    setItemsToCopy([]);
    setAllChecked(false);
  }

  async function handleDeleteFolders() {
    const parent = selectedFolder?._id;
    const params = { parent, items: selectedItems };
    setIsOpenModal('');
    setLoading(true);
    const response = await Post(
      BaseURL('data-room/delete-items'),
      params,
      apiHeader(token),
    );
    if (response !== undefined) {
      setData(response?.data?.data?.folders);
      if (selectedFolder == null) {
        setPrevFolder([...prevFolder, response?.data?.data?.folders[0]]);
      }
      toast.success('Deleted Successfully', { autoClose: 1500 });
    }
    setLoading(false);
    setSelectedItems([]);
    setAllChecked(false);
  }

  useEffect(() => {
    const news = [];

    data.forEach((node) => {
      if (node?._id == id) news.push({ ...node, pId: '0' });
      node?.children?.forEach((e) => {
        news.push({ ...e, pId: node._id });
      });
    });

    const selectedId = selectedFolder?._id || id;

    const old = treeItems.filter((e) =>
      selectedId == id
        ? e.pId != selectedId && e._id != selectedId
        : e.pId != selectedId,
    );

    setTreeItems([...old, ...news]);
  }, [data]);

  useEffect(() => {
    let tempListingFolders = [];
    tempListingFolders = data[0]?.children?.filter((item) => {
      if (item?.isFile) {
        return item?.fileName.toLowerCase().includes(search.toLowerCase());
      } else {
        return item?.name?.toLowerCase().includes(search.toLowerCase());
      }
    });
    setListingFolders(tempListingFolders);
  }, [data, search]);

  const breadcrumbData = prevFolder?.map((item) => ({
    title: item?.name,
    id: item?._id,
  }));

  return (
    <>
      <style>{`
      .table100-body{
        max-height:calc(100vh - 310px);
      }
      .table100-body td {
        padding-block: 25px;
      }
      `}</style>
      <div>
        <SideBarSkeleton>
          <div className={classes.container}>
            <div className={[classes.mainContainer]}>
              <div className={[classes.headerContainer]}>
                <Row>
                  <Col md={8} sm={12}>
                    <CustomBreadcrumb
                      data={breadcrumbData}
                      active={
                        selectedFolder == null
                          ? data[0]?._id
                          : selectedFolder?._id
                      }
                      onClick={(e) => {
                        const tempData = prevFolder?.find(
                          (item, i) => item?._id == e,
                        );
                        subSelectedFolder(tempData);

                        const tempPrev = [...prevFolder];
                        const findPrevIndex = tempPrev?.findIndex(
                          (item) => item?._id == e,
                        );
                        tempPrev.splice(findPrevIndex + 1, tempPrev?.length);
                        setPrevFolder(tempPrev);
                      }}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SearchInput
                      setter={(e) => {
                        setSearch(e);
                      }}
                      value={search}
                      placeholder={'Search'}
                      customStyle={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </div>
              <div className={classes.xScrollContainer}>
                <div className={classes.btnsDiv}>
                  <div className={classes.checkbox} onClick={handleAllChecked}>
                    {allChecked ? (
                      <CheckBoxOutlinedIcon />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon />
                    )}
                    Select All
                  </div>
                  <Button
                    label={'Copy'}
                    onClick={handleCopyClicked}
                    disabled={selectedItems.length == 0}
                  />
                  <Button
                    label={'Cut'}
                    onClick={handleCutClicked}
                    disabled={selectedItems.length == 0}
                  />
                  <Button
                    label={'Paste'}
                    onClick={() => handlePaste()}
                    disabled={action == ''}
                  />
                  <Button
                    label={'Delete'}
                    onClick={() => setIsOpenModal('deleting')}
                    disabled={selectedItems.length == 0}
                  />
                  <Button
                    label={'Create Folder'}
                    onClick={() => setShow('folder')}
                  />
                  <Button
                    label={'Upload File'}
                    onClick={() => setShow('file')}
                  />
                </div>
              </div>
              <Row>
                <Col md={4}>
                  <div className={classes.treeviewContainer}>
                    <RecursiveTreeView
                      items={treeItems}
                      selected={selectedFolder?._id || id}
                      expanded={[
                        ...prevFolder.map((e) => e._id),
                        selectedFolder?._id,
                      ]}
                      onClick={(item, parents) => {
                        setPrevFolder(parents);
                        subSelectedFolder(item);
                      }}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  {loading ? (
                    <div className={classes.noFolderMainDiv}>
                      <Loader />
                    </div>
                  ) : (
                    <div className={[classes.foldersDiv]}>
                      <div className={classes.folderBox}>
                        {listingFolders?.length == 0 ? (
                          <div className={classes.noFolderMainDiv}>
                            <NoFolderComp />
                          </div>
                        ) : (
                          listingFolders?.map((item, i) => (
                            <div className={classes.folderInnerBox} key={i}>
                              <FolderAndFileBox
                                data={item}
                                allowDelete={
                                  userRole?.includes('broker')
                                    ? item?.owner == user?._id
                                    : true
                                }
                                allowEdit={
                                  userRole?.includes('broker')
                                    ? item?.owner == user?._id
                                    : true
                                }
                                onClick={() => {
                                  const findObj = prevFolder?.find(
                                    (innerItem) => innerItem?._id == item?._id,
                                  );
                                  if (!findObj) {
                                    setPrevFolder([...prevFolder, item]);
                                  }
                                  subSelectedFolder(item);
                                }}
                                onUpdate={(type, e) => {
                                  const newAllFoler = [...data];
                                  if (type == 'edit') {
                                    newAllFoler[0].children.splice(i, 1, e);
                                  } else {
                                    newAllFoler[0].children.splice(i, 1);
                                  }
                                  setData(newAllFoler);
                                  return;
                                }}
                                onSelect={handleItemSelect}
                                selectedItems={selectedItems}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </SideBarSkeleton>
      </div>
      {show == 'folder' && (
        <CreateFolderModal
          show={show == 'folder'}
          setShow={() => setShow('')}
          isLoading={isUploading == 'folder'}
          handleSubmit={createFolder}
        />
      )}
      {show == 'file' && (
        <UploadFileInFolderModal
          show={show == 'file'}
          setShow={() => setShow('')}
          isLoading={isUploading == 'file'}
          handleSubmit={uploadFile}
        />
      )}
      {isOpenModal === 'deleting' && (
        <AreYouSureModal
          isApiCall={false}
          setShow={() => setIsOpenModal('')}
          show={isOpenModal === 'deleting'}
          onClick={handleDeleteFolders}
          subTitle={`Do you really want to delete?`}
        />
      )}
    </>
  );
};

export default CompanyFolderDetail;
