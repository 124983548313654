import moment from 'moment-timezone';
import dayjs from 'dayjs';
import jstz from 'jstz';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/fr';

export const getTimezoneName = () => {
  const timeZone = jstz.determine();
  return timeZone.name();
};

moment.tz.setDefault(getTimezoneName());

export const changeMomentTimeZone = (timeZone) => {
  const isValidTimezone = moment.tz.names().includes(timeZone);
  if (!isValidTimezone) {
    console.error(
      `Invalid timezone: ${timeZone}. Please provide a valid timezone.`,
    );
    return;
  }
  moment.tz.setDefault(timeZone);

  dayjs.tz.setDefault(timeZone);
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('fr');

dayjs.tz.setDefault(getTimezoneName());

const timezonedDayjs = (...args) => {
  return dayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return dayjs.unix(value).tz();
};

timezonedDayjs.unix = timezonedUnix;
timezonedDayjs.duration = dayjs.duration;

export { moment };

export default timezonedDayjs;
