import React, { useEffect, useState } from 'react';
import AddAutomatedEmail from '../AddAutomatedEmail';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import NotFound from '../NotFound';
import Loader from '../../Component/Loader';

const EditAutomatedEmail = () => {
  const templateId = useParams()?.slug;

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const baseApiUrl = BaseURL(`automated-email-template`);
  const [isFound, setIsFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [templateData, setTemplateData] = useState(null);

  const getTemplateById = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = `${baseApiUrl}/${templateId}`;

      const response = await Get(url, accessToken);

      if (response?.status === 200) {
        setTemplateData(response?.data);
      } else {
        setIsFound(false);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      getTemplateById();
    }
  }, [templateId]);

  return (
    <div>
      {isLoading &&  <Loader />}

      {isFound && !isLoading && <AddAutomatedEmail editData={templateData} />}
      {!isFound && !isLoading && <NotFound />}
    </div>
  );
};

export default EditAutomatedEmail;
