import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AttachmentUpload from '../../Component/AttachmentUpload';
import { Button } from '../../Component/Button/Button';
import ModalSkeleton from '../ModalSkeleton';
import classes from './UploadFileInFolderModal.module.css';

const UploadFileInFolderModal = ({
  show,
  setShow,
  handleSubmit,
  isEdit = true,
  data,
  isLoading = false,
}) => {
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      if(isEdit)
        setFile(data?.name);
      else
        setFile(data)
    }
  }, [data]);

  const HandleSubmitData = () => {
    handleSubmit({
      file,
    });
  };

  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`${data != undefined && isEdit ? 'Edit' : 'Upload'} Files`}
      >
        <div className={classes.container}>
          <Row className={classes.row}>
            <Col md={12}>
              <AttachmentUpload
                setter={(e) => setFile([...e])}
                state={file}
                onDelete={(idx) => {
                  file.splice(idx, 1);
                  setFile([...file]);
                }}
              />
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={() => HandleSubmitData()}
              className={classes.btn}
              label={
                isLoading
                  ? 'Uploading...'
                  : data != null && isEdit
                    ? 'Edit File'
                    : 'Upload File'
              }
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default UploadFileInFolderModal;
