import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { RiFolderOpenFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { apiHeader, BaseURL, CreateFormData } from '../../config/apiUrl';
import { Get, Post } from '../../Axios/AxiosFunctions';
import { Button } from '../../Component/Button/Button';
import CustomBreadcrumb from '../../Component/CustomBreadcrumb';
import FolderAndFileBox from '../../Component/FolderAndFileBox';
import Loader from '../../Component/Loader';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import RecursiveTreeView from '../../Component/TreeView';
import CreateFolderModal from '../../modals/CreateFolderModal';
import UploadFileInFolderModal from '../../modals/UploadFileInFolderModal';
import classes from './ViewTeamFolderDetail.module.css';

const NoFolderComp = () => {
  return (
    <div className={classes.noFolderComp}>
      <RiFolderOpenFill />
      <p>No Folders And Files Found In This Folder</p>
    </div>
  );
};

const BrokerFolderDetail = () => {
  const id = useParams()?.id;
  const { user, access_token: token } = useSelector(
    (state) => state?.authReducer,
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);

  // treview cache
  const [treeItems, setTreeItems] = useState([]);

  // calculate previous folder
  const [prevFolder, setPrevFolder] = useState([]);

  // For  File and Folder Modal state
  const [show, setShow] = useState('');
  const [isUploading, setIsUploading] = useState('');

  const getAllFolders = async () => {
    const url = BaseURL(
      `data-room?child=${selectedFolder == null ? id : selectedFolder?._id}`,
    );
    setLoading(true);
    const response = await Get(url, token);
    if (response !== undefined) {
      setData(response?.data?.data?.folders);
      if (selectedFolder == null) {
        setPrevFolder([...prevFolder, response?.data?.data?.folders[0]]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllFolders();
  }, [selectedFolder]);

  useEffect(() => {
    const news = [];

    data.forEach((node) => {
      if (node?._id == id) news.push({ ...node, pId: '0' });
      node?.children?.forEach((e) => {
        news.push({ ...e, pId: node._id });
      });
    });

    const selectedId = selectedFolder?._id || id;

    const old = treeItems.filter((e) =>
      selectedId == id
        ? e.pId != selectedId && e._id != selectedId
        : e.pId != selectedId,
    );

    setTreeItems([...old, ...news]);
  }, [data]);

  const createFolder = async (e) => {
    const url = BaseURL('data-room');
    let params = {
      ...e,
      parent: selectedFolder == null ? id : selectedFolder?._id,
    };
    setIsUploading('folder');

    const response = await Post(url, params, apiHeader(token));
    if (response !== undefined) {
      const newData = JSON.parse(JSON.stringify(data));
      newData[0].children = [...newData[0]?.children, response?.data?.data];
      setData([...newData]);
      toast.success('Folder created successfully');
      setIsUploading('');
      setShow('');
    } else {
      setIsUploading('');
    }
  };

  // create file
  const uploadFile = async (e) => {
    const url = BaseURL('data-room');
    let params = {
      file: e?.file,
      parent: selectedFolder == null ? id : selectedFolder?._id,
    };

    const formData = await CreateFormData(params);
    e?.roles?.map((item) => formData.append('roles[]', item));
    setIsUploading('file');

    const response = await Post(url, formData, apiHeader(token));
    if (response !== undefined) {
      const newAllFoler = [...data];
      newAllFoler[0].children = [
        ...newAllFoler[0].children,
        response?.data?.data,
      ];
      setData(newAllFoler);
      toast.success('File upload successfully');
      setIsUploading('');
      setShow('');
    } else {
      setIsUploading('');
    }
  };

  const breadcrumbData = prevFolder?.map((item) => ({
    title: item?.name,
    id: item?._id,
  }));

  const ToolBar = () => (
    <div className={classes.xScrollContainer}>
      <div className={classes.btnsDiv}>
        <Button label={'Create Folder'} onClick={() => setShow('folder')} />
        <Button label={'Upload File'} onClick={() => setShow('file')} />
      </div>
    </div>
  );

  return (
    <SideBarSkeleton>
      <div className={classes.container}>
        <div className={[classes.mainContainer]}>
          <div className={[classes.headerContainer]}>
            <CustomBreadcrumb
              data={breadcrumbData}
              active={
                selectedFolder == null ? data[0]?._id : selectedFolder?._id
              }
              onClick={(e) => {
                const tempData = prevFolder?.find((item, i) => item?._id == e);
                setSelectedFolder(tempData);
                const tempPrev = [...prevFolder];
                const findPrevIndex = tempPrev?.findIndex(
                  (item) => item?._id == e,
                );
                tempPrev.splice(findPrevIndex + 1, tempPrev?.length);
                setPrevFolder(tempPrev);
              }}
            />
          </div>
          {breadcrumbData?.length == 1 ? (
            <ToolBar />
          ) : (
            selectedFolder?.owner == user?._id && <ToolBar />
          )}

          <Row>
            <Col md={4}>
              <div className={classes.treeviewContainer}>
                <RecursiveTreeView
                  items={treeItems}
                  selected={selectedFolder?._id || id}
                  expanded={[
                    ...prevFolder.map((e) => e._id),
                    selectedFolder?._id,
                  ]}
                  onClick={(item, parents) => {
                    setPrevFolder(parents);
                    subSelectedFolder(item);
                  }}
                />
              </div>
            </Col>
            <Col md={8}>
              {loading ? (
                <div className={classes.noFolderMainDiv}>
                  <Loader />
                </div>
              ) : (
                <div className={[classes.foldersDiv]}>
                  <div className={classes.folderBox}>
                    {data[0]?.children?.length == 0 ? (
                      <div className={classes.noFolderMainDiv}>
                        <NoFolderComp />
                      </div>
                    ) : (
                      data[0]?.children
                        ?.filter((item) => item?.owner === user?._id)
                        ?.map((item, i) => (
                          <div className={classes.folderInnerBox} key={i}>
                            <FolderAndFileBox
                              data={item}
                              allowDelete={item?.owner == user?._id}
                              allowEdit={item?.owner == user?._id}
                              onClick={() => {
                                const findObj = prevFolder?.find(
                                  (innerItem) => innerItem?._id == item?._id,
                                );
                                if (!findObj) {
                                  setPrevFolder([...prevFolder, item]);
                                }
                                setSelectedFolder(item);
                              }}
                              onUpdate={(type, e) => {
                                const newAllFoler = [...data];
                                if (type == 'edit') {
                                  newAllFoler[0].children.splice(i, 1, e);
                                } else {
                                  newAllFoler[0].children.splice(i, 1);
                                }
                                setData(newAllFoler);
                              }}
                            />
                          </div>
                        ))
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {show == 'folder' && (
        <CreateFolderModal
          show={show == 'folder'}
          setShow={() => setShow('')}
          isLoading={isUploading == 'folder'}
          handleSubmit={createFolder}
        />
      )}
      {show == 'file' && (
        <UploadFileInFolderModal
          show={show == 'file'}
          setShow={() => setShow('')}
          isLoading={isUploading == 'file'}
          handleSubmit={uploadFile}
        />
      )}
    </SideBarSkeleton>
  );
};

export default BrokerFolderDetail;
