import { useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select';

import classes from './TimeZonePicker.module.css';

const TimeZonePicker = ({ value, setter, label, disabled }) => {
  return (
    <div className={classes.container}>
      {label && (
        <label
          htmlFor={`dropdown${label}`}
          className={`mb-2 ${[classes.label, disabled && classes.disabled].join(
            ' ',
          )}`}
        >
          {label}
        </label>
      )}
      <div className={classes.dropdownContainer}>
        <TimezoneSelect
          styles={{
            control: (provided) => ({
              ...provided,
              height: 55, // Adjust the height here
              border: '1px solid #e5e5e5',
              boxShadow: '0px 0 5px 2px #0000000d',
              borderRadius: '10px',
            }),
          }}
          value={value}
          onChange={(data) => {
            setter(data?.value);
          }}
        />
      </div>
    </div>
  );
};

export default TimeZonePicker;
