import React, { useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import { mediaUrl, imageUrl } from '../../config/apiUrl';
import classes from './treeview.module.css';

const RecursiveTreeView = ({ items, expanded, selected, onClick }) => {
  items = items || [];

  const findParents = (ele) => {
    if (ele.pId == 0) return [ele];

    const index = items?.findIndex((e) => ele.pId == e._id);
    if (index < 0) return [];
    return [...findParents(items[index]), ele];
  };

  const renderTree = (node) => {
    let children = [];
    if (!node.isFile) children = items.filter((ele) => ele.pId == node._id);

    return (
      <TreeItem
        key={node._id}
        nodeId={node._id}
        onClick={() => {
          node?.isFile
            ? window.open(
                ['pdf', 'docx', 'xlsx', 'csv']?.includes(
                  node?.name?.split('.')[1],
                )
                  ? `${mediaUrl(node?.name)}`
                  : `${imageUrl(node?.name)}`,
              )
            : onClick(node, findParents(node));
        }}
        label={
          <div className={[classes.items]}>
            {node.isFile ? node.fileName : node.name}
          </div>
        }
      >
        {!children.length && !node.isFile ? (
          <></>
        ) : (
          children.map((ele) => renderTree(ele))
        )}
      </TreeItem>
    );
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      expanded={expanded}
      className={classes.treeview}
    >
      {items.filter((node) => node.pId == '0').map((node) => renderTree(node))}
    </TreeView>
  );
};

// https://github.com/mui/mui-x/issues/6019
// https://codesandbox.io/p/sandbox/typescript-data-driven-treeview-dhkx2?file=%2Fpackage.json%3A3%2C22

export default RecursiveTreeView;
