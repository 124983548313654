import SideBarSkeleton from '../../Component/SideBarSkeleton';
import ListingFolderDetail from './ListingFolderDetail';
import classes from './ViewTeamFolderDetail.module.css'

const ListingFolderDetailContainer = () => {
  return (
    <SideBarSkeleton>
      <div className={classes.container}>
        <ListingFolderDetail />
      </div>
    </SideBarSkeleton>
  );
};

export default ListingFolderDetailContainer